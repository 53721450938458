import * as React from "react"
import AnimatedDiv from "./AnimatedDiv"
import { StaticImage } from "gatsby-plugin-image"


const Hero = () => (
	<div className="homeSecA">
		<div className="container">
			<div className="row align-items-center">
				<AnimatedDiv className="col-md-6">
					<div className="homeSecAText">
						<h1>All in one Masonic Lodge Management Software</h1>
						<div className="desc">Leading Organizations are using Olympus to ease operations, increase revenue and membership</div>
						<a
							href="#contactSec"
							className="yellowBtn">
						Get Started
						</a>
					</div>
				</AnimatedDiv>
				<AnimatedDiv className="col-md-6">
					<figure className="img">
						<StaticImage src='../assets/images/dashboard.svg' alt="" />
					</figure>
				</AnimatedDiv>
			</div>
		</div>
	</div>
)

export default Hero
