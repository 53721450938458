import * as React from "react"
import config from "../config"
import AnimatedDiv from "./AnimatedDiv";


const Benefits = () => {
	return (
		<>
			<div className="homeSecB">
				<div className="container">
					<AnimatedDiv className="homeHeading">
						<h2>Benefits</h2>
						<div className="desc">
						 All in one Masonic Lodge Management Software can help you ease your operations, increase revenue and membership. You will get a state of the art modern website optimized for great SEO and a content management system included with your subscription. 
						</div>
					</AnimatedDiv>
				</div>
			</div>

			{
				config.benefits.map((benefit, i) => {
					i++;
					let imageAlignment = benefit.imageAlignment === 'right' ? 'order-md-1' : '';

					return (
						<div id={'benefitSec' + i} className={'benefits ' + benefit.class} style={{ background: benefit.bgcolor }} key={i}>
							<div className="container" >
								<div className="row align-items-center">
									<AnimatedDiv className={'col-md-6 ' + imageAlignment} >
										<figure className="img">
											{/* <img src={withPrefix('images/' + benefit.image)} alt="" /> */}
											{benefit.image}
										</figure>
									</AnimatedDiv>
									<AnimatedDiv className="col-md-6">
										<div className="homeBlock">
											<h3>{benefit.title}</h3>
											<div className="desc">{benefit.description}</div>
											<ul className="tikPoints">
												{
													benefit.list.map((item, i) => {
														return (
															<li key={i}>{item}</li>
														)
													})
												}
											</ul>

											<a href={benefit.cta.action} className="borderBtn">{benefit.cta.label}</a>
										</div>
									</AnimatedDiv>
								</div>
							</div>
						</div>
					)
				})
			}
		</>
	)
}

export default Benefits;
