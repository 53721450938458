import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import SubscriptionCard from "./SubscriptionCard"
import config from "../../config"
import AnimatedDiv from "../AnimatedDiv";

const Subscriptions = () => {

  const [state, setState] = useState({
    pricePlans: 'Monthly'

  });

  function togglePricing(e, param) {
    e.preventDefault();
    setState({ pricePlans: param === 'monthly' ? 'Monthly' : 'Annual' });
  }

  return (
    <StaticQuery
      query={graphql`
        query ProductPrices {
          prices: allStripePrice(
            filter: { active: { eq: true } }
            sort: { fields: [unit_amount] }
          ) {
            edges {
              node {
                id
                active
                currency
                unit_amount
                product {
                  id
                  name
                }
              }
            }
          }
        }
      `}
      render={({ prices }) => {
        // Group prices by product
        const products = {}
        for (const { node: price } of prices.edges) {

          const product = price.product
          const cost = {
            active: price.active,
            currency: price.currency,
            id: price.id,
            unit_amount: price.unit_amount
          }

          cost.has_discount = (price.unit_amount > 50000)
          cost.discount = config.pricing.discount


          if (!products[product.id]) {
            products[product.id] = product
            products[product.id].prices = []
          }
          products[product.id].prices.push(cost)
        }

        prices.edges.map((data) => {
          if (data.node.product.id in config.pricing) {
            products[data.node.product.id].plan_id = config.pricing[data.node.product.id].plan_id
            products[data.node.product.id].plan = state.pricePlans
            products[data.node.product.id].features = config.pricing[data.node.product.id].features
            products[data.node.product.id].icon = config.pricing[data.node.product.id].icon
            products[data.node.product.id].cta = config.pricing[data.node.product.id].cta
          }
        })

        // console.log("#######################################: ", products)
        return (
          <div className="homePricingSec" id="pricingSec">
            <div className="container">

              <AnimatedDiv className="homeHeading animation">
                <h2>Pricing</h2>
                <div className="desc">
                  Cannot work with these prices? Not a problem, contact us and we will be happy to work with your organization!
                </div>
              </AnimatedDiv>
              <AnimatedDiv className="pricingTabSec">
                <ul className="pricingNav">
                  <li className={state.pricePlans === 'Monthly' ? 'active' : ''}><a href="/" onClick={(e) => togglePricing(e, 'monthly')}>Monthly</a></li>
                  <li className={state.pricePlans === 'Annual' ? 'active' : ''}><a href="/" onClick={(e) => togglePricing(e, 'annual')}>Annual</a></li>
                </ul>
                <div className="pricingContainer">
                  <div className="pricingBlock">
                    <div className="row">

                      {Object.keys(products).map(key => (
                        !products[key].name.includes("Test") && <SubscriptionCard key={products[key].id} product={products[key]} />
                      ))}
                    </div>
                  </div>
                </div>

              </AnimatedDiv>
            </div>
          </div>
        )
      }}
    />

  )
}

export default Subscriptions
