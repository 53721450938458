import * as React from "react"
import config from "../config"

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination"
import SwiperCore, {
	Pagination
  } from 'swiper';
import AnimatedDiv from "./AnimatedDiv";

  SwiperCore.use([Pagination]);
const Testimonials = () => {
	return (
	<>
	<AnimatedDiv id="testimonials" className="homeTestiSec">
		<div className="container">
			<div className="homeHeading">
				<h2>Testimonials</h2>
				</div>
				<Swiper pagination={
					{"dynamicBullets": true}
					} className="mySwiper">
						{
						config.testimonials.map((testimonial, i)=>{
							return(
							<>
							<SwiperSlide>
								<div className="testiItem" key={i}>
								<div className="userImg">{testimonial.image}</div>
								<div className="textA">{testimonial.name}</div>
								<div className="textB">{testimonial.job}</div>
								<div className="textC"><span className="icon"></span></div>
								<div className="textD">{testimonial.description}</div>
								</div>
							</SwiperSlide>		
							</>
						)
						})
					}
				</Swiper>
				</div>
			</AnimatedDiv>
		</>
)
}

export default Testimonials;