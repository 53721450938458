import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const config = {
	benefits: [{
		title: "Email Marketing",
		description: "Increase brotherly engagement with our beatutiful Masonic email templates or create your own xustom email designs. You can send",
		image: <StaticImage src="./assets/images/olympus-02.png" alt="" />,
		list: ["EVENT INVITES", "DUES REMINDERS", "NEWSLETTER",],
		class: '',
		cta: {
			label: "See More",
			action: "#benefitSec2"
		},
		imageAlignment: "right"
	},
	{
		title: "Communicate Effectively",
		description: "Past masters know the pain of trying to reach and engage each individual brother. With Olympus on average Lodges see a 95% increase with our centralized member portal.",
		image: <StaticImage src="./assets/images/olympus-03.png" alt="Communication"/>,
		list: ["NOTIFICATIONS", "SMS MESSAGE", "CHAT BOX", ],
		class: 'homeSecC',
		cta: {
			label: "See More",
			action: "#benefitSec3"
		},
		imageAlignment: "left",
		bgcolor: "#EFF3F9"
	},
	{
		title: "Manage Events",
		description: "It's important to have a great tool for your events, and the best one in the market is Olympus. Manage all aspects of an event from RSVP, check-in to payment!",
		image: <StaticImage src="./assets/images/olympus-04.png" alt="Events"/>,
		list: ["ADD TO CALENDAR", "TAKE PAYMENTS", "ORGANIZE RSVP", "MANAGE CHECK-IN"],
		class: 'homeSecD',
		cta: {
			label: "See More",
			action: "#benefitSec4"
		},
		imageAlignment: "right"
	},
	{
		title: "Take Payments",
		description: "Join the thousands of Lodges and other organizations that accept payments online without incurring a transaction fee.",
		image: <StaticImage src="./assets/images/olympus-05.png" alt="Payments"/>,
		list: ["DUES", "DONATIONS", "DINNERS", "EVENT FEE"],
		class: 'homeSecE',
		cta: {
			label: "See More",
			action: "#benefitSec5"
		},
		imageAlignment: "left",
		bgcolor: "#EFF3F9"
	},
	{
		title: "Manage Users",
		description: "With the right tools, managing a Masonic Lodge memberships can be fun and easy!",
		image: <StaticImage src="./assets/images/olympus-06.png" alt="Management"/>,
		list: ["MANAGE OFFICERS", "CREATE GROUPS", "MEMBER ID DATABASE"],
		class: 'homeSecD',
		cta: {
			label: "See More",
			action: "#benefitSec6"
		},
		imageAlignment: "right"
	},
	{
		title: "Online Store",
		description: "Creating an online store for your organization is a great way to raise money. Besides donating, members can buy items and you'll earn revenue every time they do so.",
		image: <StaticImage src="./assets/images/olympus-07.png" alt="Store"/>,
		list: [],
		class: 'homeSecF',
		cta: {
			label: "See More",
			action: "#benefitSec7"
		},
		imageAlignment: "left",
		bgcolor: "#EFF3F9"
	},
	{
		title: "Blog",
		description: "Easily create or have members submit their own lectures or articles to your website.",
		image: <StaticImage src="./assets/images/olympus-08.png" alt="Blog"/>,
		list: [],
		class: 'homeSecD',
		cta: {
			label: "See More",
			action: "#benefitSec8"
		},
		imageAlignment: "right"
	},
	{
		title: "Sponsored Business Listing",
		description: "Create a new stream of revenue by offering a space for brothers to publish their businesses in exchange for an sponsorship donation.",
		image: <StaticImage src="./assets/images/olympus-09.png" alt="Listing"/>,
		list: [],
		class: 'homeSecE',
		cta: {
			label: "See Pricing",
			action: "#pricingSec"
		},
		imageAlignment: "left",
		bgcolor: "#EFF3F9"
	}],
	pricing: {
				discount: "5% Discount",
				prod_KOERBG2s38TRug: {
					plan_id: 1,
					icon: "price-icon-1.png",
					features: [
						"Up to 100 Users",
						"Business Sponsorship",
						"Free Website",
						"Email Marketing",
						"Degrees Management",
						"Event Engine",
						"Blog and Lectures",
						"Users Management",
						"Online Payments"
					],
					cta: {
						label: "Get Started",
						action: "#"
					}
				},
			prod_KOhw7Egd312jVb: {
					plan_id: 2,
					icon: "price-icon-2.png",
					features: [
						"Up to 200 Users",
						"Business Sponsorship",
						"Free Website",
						"Email Marketing",
						"Degrees Management",
						"Event Engine",
						"Blog and Lectures",
						"Users Management",
						"Online Payments",
						"Digital Magazine"
					],
					cta: {
						label: "Get Started",
						action: "#"
					}
				},
				prod_KOESjaIlm7U0NB: {
					plan_id: 3,
					icon: "price-icon-3.png",
					features: [
						"Up to 500 Users",
						"Business Sponsorship",
						"Free Website",
						"Email Marketing",
						"Degrees Management",
						"Event Engine",
						"Blog and Lectures",
						"Users Management",
						"Online Payments",
						"Digital Magazine",
						"Online Store"
					],
					cta: {
						label: "Get Started",
						action: "#"
					}
				}
	},
	faqs: [
		{
			title: "Does Olympus have a way to make content private?",
			description: "Yes. You can choose to show content to registered members only or publictly to anyone that visits your website."
		},
		{
			title: "How does Olympus handle payment information?",
			description: "All payment information is stored at the payment processing company Stripe on a PCI compliant vault."
		},
		{
			title: "How can there be no transaction fees for any payment?",
			description: "Olympus charges the transaction fee to the payee."
		},
		{
			title: "What is a Content Management System (CMS)?",
			description: "Olympus offers a simplified, easy to use content management system to change all the information on your website with easy. There is no need for a programmer anymore."
		},
		{
		title: "Does Olympus integrate with 3rd party apps?",
		description: "Yes. It can currently integrate with Sociocs."
		}
		],
	testimonials: [{
		name: "Mark Berkson",
		job: "Secretary, Valley of Rockville Centre, Long Island, NY",
		description: "The most talented, knowledgeable and responsible team of professionals I know. The BluePortal team created a web application for my organization and the accolades received were off the charts. They are extremely conscientious when it comes to creativity, listening, returning phone calls and incorporating your ideas into a most professional website. It is without a doubt that I would recommend BluePortal to others, and I would not hesitate to utilize their talents again.",
		image: <StaticImage src="./assets/images/631156120311.png" alt="" />,
	},
],
}

export default config;