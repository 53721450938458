import React, {useState} from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import AnimatedDiv from "./AnimatedDiv";


const ContactUs = () => {
	return (
		<>
			<div className="homeContactSec" id="contactSec">
				<div className="container">
					<form method="POST" action="https://forms.sociocs.com/v1/kFLlbX5JLqBdeDfdtUnc/1633881123602111">
					<div className="row align-items-center">
						<AnimatedDiv className="col-md-6 order-md-1">
							<figure className="img">
								<StaticImage src="../assets/images/olympus-10.png" alt="" />
							</figure>
						</AnimatedDiv>
						<AnimatedDiv className="col-md-6">
							<div className="homeHeading">
								<h2>Contact</h2>
								<div className="desc">Contact us today and we will get back to you within 48 hours.</div>
							</div>
							<div className="contactForm">
								<div className="form-group">
									<div className="input-Icon">
										<input className="form-control" placeholder="Name*" type="text" name="name" />
									</div>
								</div>
								<div className="form-group">
									<div className="input-Icon msg">
										<input className="form-control" placeholder="Email*" type="text" name="email" />
									</div>
								</div>
								<div className="form-group">
									<textarea className="form-control" placeholder="Message *"></textarea>
								</div>
								<button className="blueBtn" type="submit">Send Message</button>
							</div>
						</AnimatedDiv>
					</div>
						</form>
				</div>
			</div>
			</>
	)
}

export default ContactUs;
