/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */

 let stripePromise
 const getStripe = async () => {
   if (!stripePromise) {
     const { loadStripe } = await import('@stripe/stripe-js') 
     stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
   }
   return stripePromise
 }
 
 export default getStripe
 