import * as  React from "react"
import SlideDown from "react-slidedown";
import config from "../config";
import AnimatedDiv from "./AnimatedDiv";

export const Faq = ({ faq, index }) => {
	const [isOpen, setIsOpen] = React.useState(index === 1)
	const counter = React.useMemo(() => index <= 9 ? '0' + index : index, [index])
	return (
		<AnimatedDiv className={'faqBox' + ( isOpen ? ' active' : '')} key={index}>
			<span className="count">{counter}</span>
			<div className="faqQ" onClick={() => setIsOpen(!isOpen)}>{faq.title}</div>
			<SlideDown>
				{
					isOpen && <div className="faqAnd">{faq.description}</div>
				}
			</SlideDown>
		</AnimatedDiv>
	)
}

const Faqs = () => {
	return (
		<>
			<div className="homeFaqSec" id="faqSec">
				<div className="container">
					<AnimatedDiv className="homeHeading">
						<h2>FAQ</h2>
						<div className="desc">The most frequently asked questions are:</div>
					</AnimatedDiv>
					<div className="faqContentArea">
						{
							config.faqs.map((faq, i) => <Faq key={i} faq={faq} index={i + 1} />)
						}
					</div>
				</div>
			</div>
		</>
	)

}

export default Faqs;
