import * as React from "react"

// Check if window is defined (so if in the browser or in node.js).
const isBrowser =  typeof document !== `undefined`

const Chat = () => {
if (isBrowser) {
  (function(d, s, id) {

      if (d.getElementById(id)) return;
      var nst = d.createElement(s);
      nst.id = id;
      nst.src = "https://static.sociocs.com/c2c-sms.js";
      var st = d.getElementsByTagName(s)[0];
      st.parentNode.insertBefore(nst, st);

  }(document, "script", 'sociocs-c2c-script'));
}

return (
  <>
  <div
    id="sociocs-c2c"
    data-channel="oPvyMJcKgAj7N5OpBJcR/c2csms_1638739507626170"
    data-primary-color="#000F1F"
    data-prompt-text="Need Help? Start a conversation now"
  >
  </div>
  </>
  )
}

export default Chat
