import React, { useState } from "react"
import getStripe from "../../utils/payment"
import { withPrefix } from "gatsby"
import AnimatedDiv from "../AnimatedDiv"


const formatPrice = (amount, currency) => {
  let price = (amount / 100).toFixed(2)
  let numberFormat = new Intl.NumberFormat(["en-US"], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  })
  return numberFormat.format(price)
}


const formatSubscriptionAmount = (amount) => {
    if(amount > 50000){
      return amount/12
    }
    else{
      return amount
    }


}

const SubscriptionCard = ({ product }) => {
  const [loading, setLoading] = useState(false)


  var index;
  if (product.plan === 'Annual') {
    index = 1
  }
  else{
    index = 0
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)

    // const price = new FormData(event.target).get("priceSelect")
    const price = product.prices[index].id

    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      mode: "subscription",
      lineItems: [{ price, quantity: 1 }],
      successUrl: `${window.location.origin}/page-2/`,
      cancelUrl: `${window.location.origin}`,
    })

    if (error) {
      console.warn("Error:", error)
      setLoading(false)
    }
  }


  return(
    <>

      <AnimatedDiv className="col-md-4">
        <form onSubmit={handleSubmit}>
                <div className={'pricBox plan' + product.plan_id}>
                  <div className="row pricBoxHed no-gutters" >
                    <div className="col-auto">
                      <div className="icon" >
                        <img src={withPrefix('images/'+ product.icon)} alt={product.name} />
                      </div>
                    </div>
                    <div className="col">
                      {index === 1 &&(
                        <div className="textA">
                          {product.prices[index].discount}
                        </div>)  }
                      <div className="textB">
                        <fieldset name="priceSelect">
                        {formatPrice(formatSubscriptionAmount(product.prices[index].unit_amount), product.prices[index].currency)}
                        </fieldset>
                        </div>

                    </div>
                  </div>
                  <h3>
                    {product.name}
                  </h3>
                  {product.features.map((feature, i) => {
                    return (
                      <ul className="pricingPoints" key={i}>
                        <li>{feature}</li>
                      </ul>
                    )
                  })
                  }
                  <button className="blueBtn">{product.cta.label}</button>
                </div>

        </form>
      </AnimatedDiv>
   </>
  )

}

export default SubscriptionCard
