import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Benefits from "../components/benefits"
import Faqs from "../components/faqs"
import Testimonials from "../components/testimonials"
import ContactUs from "../components/contactUs"
import Chat from "../components/chat"
import Subscriptions from "../components/Subscriptions/Subscriptions"


const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hero />
		<Benefits />
    <Subscriptions />
    <Faqs />
    <ContactUs />
    <Testimonials />
    <Chat />
  </Layout>

)

export default IndexPage
